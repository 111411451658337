.sm-scrum-maturity-graph {
	--graph-border-width: 2px;
	display: flex;
	flex-direction: row;
	border: solid #000000;
	border-width: var(--graph-border-width) 0 var(--graph-border-width)
		var(--graph-border-width);
	height: 50px;
}

.sm-scrum-maturity-graph-box {
	position: relative;
	display: flex;
	border: solid #000000;
	border-width: 0 var(--graph-border-width) 0 0;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.sm-scrum-maturity-graph-box-percentage {
	font-size: 0.85em;
}

.sm-scrum-maturity-graph-box-percentage span {
	font-size: 0.9em;
}

.sm-scrum-maturity-graph-box-selected {
	background-color: #66c2c9;
}

.sm-scrum-maturity-graph-box-marker {
	position: absolute;
	display: none;
	bottom: -7px;
	left: auto;
	right: auto;
}

.sm-scrum-maturity-graph-box-selected .sm-scrum-maturity-graph-box-marker {
	display: block;
}

.text-decoration-underline {
	text-decoration: underline;
}

#arrow-line {
	stroke: black;
	stroke-width: 1;
	/*marker-end:'url(#head)';*/
	/*x2: 200;*/
}
