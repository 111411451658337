.sm-scrum-maturity-display-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.sm-scrum-maturity-display-circle-container {
	display: flex;
}

.sm-scrum-maturity-display-circle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: transparent;
	border: 1px solid #602483;
	margin-right: 5px;
	margin-left: 5px;
}

.sm-scrum-maturity-display-circle-filled {
	background-color: #602483;
}

.sm-scrum-maturity-display-explanation {
	max-width: 300px;
	font-size: 12px;
	color: #797979;
	padding-top: 10px;
}

.sm-scrum-maturity-display-info-icon {
	height: 12px !important;
	width: 12px !important;
	padding-left: 2px !important;
}
