.sm-talent-review-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.sm-talent-review-status-container {
	display: flex;
	gap: 50px;
}

.sm-label {
	margin-top: 25px !important;
}

.sm-label label {
	top: -42px;
}

.sm-label:has(> .MuiAutocomplete-root) label {
	left: -13px;
	top: -38px;
}

.sm-label-datepicker {
	position: relative;
	top: -12px;
}

.sm-label-datepicker label {
	position: relative;
	top: 6px;
	margin-top: 0;
}

.sm-y-center {
	align-items: center;
}

@media (max-width: 1049.98px) {
	.sm-half-screen,
	.sm-third-screen {
		width: 100% !important;
	}

	.sm-label-datepicker {
		top: -23px;
	}
}
