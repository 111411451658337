* {
	--outer-margin: 50px;
}

h4 {
	margin-bottom: 0.5em;
}

.sm-profile-form-already-submitted {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
}

.sm-profile-preview {
	--min-gap: 10px;
	min-height: calc(100vh - 80px);
	line-height: 1.4;
}

.sm-profile-preview-grid-container {
	display: grid;
	grid-template-columns: 1fr 700px;
	grid-template-rows: auto;
	grid-template-areas:
		'head head'
		'talent employer';
	justify-content: center;
}

.sm-profile-preview-talent-head {
	grid-area: head;
}

.sm-profile-preview-talent {
	grid-area: talent;
}

.sm-profile-preview-employer {
	grid-area: employer;
	width: fit-content;
	justify-self: center;
}

.sm-profile-preview > * {
	margin-bottom: 50px;
}

.sm-profile-preview h2 {
	color: #602483;
}

.sm-profile-preview-box {
	padding: 50px;
	--total-padding-x: calc(var(--padding-x) * 3.5);
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: var(--sm-max-width);
	gap: var(--min-gap);
}

.sm-profile-preview-padding-x {
	margin: 0 30px 30px 0;
}

.sm-profile-preview-header {
	min-height: 150px;
	min-width: 100%;
	background: linear-gradient(180deg, #602483, #66c2c9);
}

.sm-profile-preview-header-content {
	margin-left: var(--outer-margin);
}

.sm-profile-preview-header-avatar {
	height: 80px;
	position: relative;
	top: 100px;
}

.sm-profile-preview-user-info {
	margin: 0 0 50px;
}

.sm-profile-preview-user-info-icon-text {
	display: flex;
	align-items: center;
	width: fit-content;
	justify-content: space-between;
	gap: var(--min-gap);
}

.sm-profile-preview-tagline {
	max-height: fit-content;
	position: relative;
	top: -20px;
}

/* to come later - please leave in */
.sm-profile-preview-header-social-media > img {
	margin-top: var(--min-gap);
	max-height: 25px;
}

.sm-profile-user-info-scrum-maturity {
	padding: 0;
	display: flex;
	justify-content: space-between;
	max-width: var(--sm-max-width);
	gap: var(--min-gap);
}

.sm-profile-preview-user-intro h2 {
	margin: 0;
}

.sm-profile-preview-user-intro {
	background-color: #e7f5f6;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
}

.sm-profile-preview-user-eval-answer {
	display: block;
}

.sm-profile-preview-teal-outline-box {
	border: 1px solid #00000033;
	background-color: #e7f5f6;
	border-radius: 20px;
	line-height: 1.4;
}

.sm-profile-preview-pink-outline-box {
	border: 1px solid #00000033;
	background-color: #f2edf5;
	border-radius: 20px;
}

.sm-profile-preview-teal-outline-box > div {
	min-width: 100%;
}

.sm-profile-preview-box hr {
	--hr-color: #00000033;
	border: none;
	height: 1px;
	color: var(--hr-color); /* old IE */
	background-color: var(--hr-color); /* Modern Browsers */
	margin: 30px 0;
}

.sm-profile-preview-box p,
.sm-profile-preview-box ul {
	margin: 0.5em 0;
}

.sm-profile-preview-header-back-button {
	font-size: 10px !important;
	position: relative;
	top: -50px;
}

.sm-button-container {
	margin-top: 30px;
}

@media (max-width: 919.98px) {
	* {
		--outer-margin: 15px;
	}

	.sm-profile-preview-padding-x {
		margin: 0 0 30px 0;
	}

	.sm-profile-preview-box {
		margin: auto;
		padding: 10px;
	}

	.sm-profile-scrum-maturity {
		align-self: center;
	}
}

@media (max-width: 1499.98px) {
	.sm-profile-preview-grid-container {
		grid-template-columns: 1fr;
		grid-template-areas:
			'head'
			'employer'
			'talent';
	}
}
