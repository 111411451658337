.talent-cards-container {
	margin-top: 50px;
	width: 100%;
	max-width: 1200px;
}

.sm-card-link {
	text-decoration: none;
	color: #000;
}

.sm-card {
	margin-bottom: 30px;
	border: 1px solid #e0e0e0;
	width: 100%;
}

.sm-card-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.sm-card-image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20%;
}

.sm-card-image > img {
	max-height: 130px;
}

.sm-card-info {
	width: 77%;
}

.sm-card-connected {
	background-color: #e7f5f6 !important;
}

.sm-card-last-visited {
	border: 2px solid #602483;
}

.sm-card-info .sm-third-screen > ul {
	list-style-type: none;
	padding-left: 0;
}

.sm-card-info .sm-third-screen > ul > li {
	display: flex;
	align-items: flex-start;
	gap: 5px;
}

.sm-card-info .sm-third-screen > ul > li > span {
	position: relative;
	top: 3.3px;
}

.sm-card-info .sm-third-screen > ul > li:not(:last-child) {
	margin: 0 20px 10px 0;
}

.sm-card-column-1,
.sm-card-column-2 {
	max-width: 240px;
}

@media (max-width: 1144.98px) {
	.sm-card-info .sm-third-screen > ul > li {
		margin-bottom: 0;
	}

	.sm-card-content {
		flex-direction: column;
		justify-content: left;
	}

	.sm-card-info {
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 1046.98px) {
	.sm-card-info .sm-form-inline-container {
		gap: 0;
	}

	.sm-card-column {
		--margin: 5px;
		margin-top: var(--margin);
		margin-bottom: var(--margin);
	}

	.talent-cards-container {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}

	.sm-card {
		width: 49%;
		margin: 0 auto;
	}
}

@media (max-width: 749.98px) {
	.sm-card {
		width: 100%;
	}

	.sm-card-info .sm-third-screen > ul {
		width: 100%;
	}

	.sm-card-column-1,
	.sm-card-column-2 {
		max-width: 290px;
	}
}
