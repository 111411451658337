.sm-profile-preview-contact-talent-section {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
}

.sm-profile-preview-contact-talent-section .sm-button {
	width: 190px;
}

.sm-profile-preview-contact-talent-section .sm-profile-preview-contact-tip {
	max-width: 330px;
}
