.container {
	padding-top: 0;
}

.sm-form-inline-container,
.sm-form-name-container {
	display: flex;
	gap: 10px;
}

.sm-form-inline-container textarea {
	width: calc(100% - 33px);
	padding: 16.5px;
}

.sm-form-inline-container
	div:not(
		.MuiInputAdornment-root,
		.MuiChip-root,
		.MuiAutocomplete-endAdornment
	) {
	width: 100%;
}

.sm-form-inline-container > * {
	flex: 1;
	min-width: 0;
}

.sm-form-half-row {
	max-width: 50%;
}

.sm-start-date-input,
.sm-start-date-input > div {
	width: 100%;
}

.sm-custom-input {
	margin: 12px 0 !important;
}

.sm-button-container {
	display: flex;
	gap: 10px;
}

.sm-checkbox-icon {
	color: #797979;
	font-size: 16px !important;
}

.sm-checkbox {
	margin-right: 16px;
	margin-left: -11px;
	display: flex;
	align-items: center;
}

.sm-checkbox label {
	margin: 0;
	color: #000;
}

.sm-profile-form-work-with-me-container {
	display: flex;
	flex-direction: column;
	margin-top: 24px;
	gap: 10px;
}

.sm-profile-form-single-line-input-field {
	margin-top: 8px !important;
	margin-bottom: 8px !important;
}

.sm-talents-terms-container .MuiCheckbox-root {
	padding-left: 0;
}

.sm-talents-terms-container .sm-terms-of-service-checkbox-container {
	margin: 0;
}

.sm-talents-terms-container .terms-of-service-checkbox-label > label {
	margin-bottom: 0;
}

.sm-talents-terms-container .sm-recruiter-login-form-error-message {
	width: fit-content;
	margin: 50px 0;
}

@media (max-width: 1049.98px) {
	.sm-form-half-row {
		max-width: 100%;
	}

	.sm-form-name-container,
	.sm-form-inline-container {
		flex-direction: column;
	}

	.sm-form-name-container {
		gap: 0px;
	}
}
