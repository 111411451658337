.sm-sidebar-and-form-body {
	display: flex;
	min-height: 500px;
}

.sm-sidebar-and-form-body-form {
	margin-right: auto;
	margin-left: auto;
	padding: 20px 50px;
	width: 100%;
}

.sm-sidebar-and-form-body-form-centered {
	margin: auto;
}

.sm-sidebar {
	min-height: calc(100vh - 80px);
	overflow: hidden;
	min-width: 290px;
	max-width: 290px;
}

.sm-sidebar-step-button {
	width: 100%;
}

/* medium-small screens - tablets */
@media (max-width: 919.98px) {
	.sm-sidebar {
		display: none;
	}
}

/* small screens - mobile */
@media (max-width: 544.98px) {
	.sm-sidebar-and-form-body-form {
		padding: 20px;
	}
}
