/* .sm-login {
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: calc(10px + 2vmin);
	color: #000000;
} */

.MuiButton-startIcon {
	margin-right: 20px !important;
}

.sm-info-text {
	font-size: 12px;
	margin: 30px auto auto;
	text-align: center;
}

.sm-info-text > p {
	margin-top: 0;
}

.sm-info-text > a {
	color: #602483;
	text-decoration: none;
}

.terms-of-service-checkbox-label > label {
	margin: 0px auto 15px;
	text-align: center;
	font-size: 24px;
}

.sm-terms-of-service-checkbox-container {
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 20px;
	margin: 20px auto;
}

.sm-linked-in-error-text {
	font-size: 20px;
	font-weight: 400;
	margin: 5px auto;
}

a.sm-linked-in-error-text {
	margin-left: 0px;
}

.linkedin-login-button {
	margin: 0px auto 10px !important;
}

/* small screens - mobile */
@media (max-width: 919.98px) {
	.sm-terms-of-service-checkbox-container {
		flex-direction: column;
		gap: 10px;
		align-items: start;
	}

	.sm-terms-of-service-checkbox-container > button {
		margin: 0 auto 5px;
	}
}
