.sm-talents-table-container {
	width: 100%;
	max-width: 1550px;
	margin: auto;
}

.sm-talents-table {
	border-radius: 5px;
}

.sm-talents-table td a {
	color: #000;
	text-decoration: none;
}
