body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	font-family: 'Open Sans', system-ui, sans-serif;
}

:root {
	--basic-padding: 20px;
	--vertical-button-spacing: 20px;
	--sm-button-border-radius: 9999px;
	--sm-button-gradient: linear-gradient(90deg, #66c2c9, #602483);
	--sm-secondary-button-text-color: #000;
	--sm-secondary-button-background-color: #e7e7e7;
	--sm-text-button-label-text-color: #000;
	--sm-max-width: 1500px;
}

.sm-text-button {
	background-color: transparent;
	color: var(--sm-text-button-label-text-color);
}

.sm-text-button:hover {
	background-color: #f2edf5;
	color: #602483;
}

.sm-text-button {
	--sm-text-button-label-font-weight: 400;
	--sm-text-button-label-text-color: #602483;
	text-decoration: none;
}

.sm-button {
	border: 0px;
	padding: 10px 15px;
	border-radius: var(--sm-button-border-radius);
	font-size: 16px;
	letter-spacing: 6%;
	font-weight: 400;
}

.sm-button:hover {
	cursor: pointer;
}

.sm-button-container {
	margin: 20px 0px;
}

.sm-preferred-white-outline-box {
	border: 1px solid #602483;
	border-radius: 20px;
	justify-content: left;
	box-shadow: 0 3px 10px #00000033;
}

.sm-box-shadow {
	box-shadow: 0 3px 10px #00000033;
}

.sm-blur {
	--blur-value: 6px;
	filter: blur(var(--blur-value));
	-webkit-filter: blur(var(--blur-value));
	/* disable text selection */
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.sm-clickable-text {
	cursor: pointer;
	text-decoration: underline;
	color: var(--sm-link-blue);
}

.sm-clickable-text:hover {
	color: #602483;
}

.sm-filter-input {
	margin-top: 25px !important;
}
