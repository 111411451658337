.sm-recruiter-filter-buttons-container {
	max-width: 1200px;
	margin: 10px 0 30px;
	display: flex;
	gap: 10px;
}

.sm-form-inline-recruiter-container {
	gap: 15px;
}

.sm-date-outside-label {
	margin-top: 30px !important;
}

.sm-date-outside-label fieldset {
	height: 55px;
}

.sm-date-outside-label input {
	padding-top: 13px !important;
}

.sm-date-outside-label button {
	top: -1px;
}

.sm-date-outside-label label {
	top: -27px;
	transition: none;
	transform: none;
}

.sm-date-outside-label legend {
	display: none;
}

.sm-recruiter-results {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-content: center;
}

@media (max-width: 1214.98px) {
	.sm-date-outside-label {
		margin-top: 33px !important;
	}
}

@media (max-width: 544.98px) {
	.sm-recruiter-filter-buttons-container {
		flex-direction: column;
	}

	.sm-recruiter-results {
		flex-direction: column;
	}
}
