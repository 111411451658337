.sm-sidebar-menu {
	--sm-sidebar-menu-spacer: 50px;
	--sm-sidebar-menu-gap: 20px;
	display: flex;
	flex-direction: column;
	color: #fff;
	gap: var(--sm-sidebar-menu-gap);
	padding-top: var(--sm-sidebar-menu-spacer);
}

.sm-sidebar-menu > div,
.sm-sidebar-menu > button {
	margin-left: 30px;
}

.sm-sidebar-menu > button {
	padding-left: 2%;
}

.sm-sidebar-menu-stepper {
	display: flex;
	flex-direction: column;
	margin-top: var(--sm-sidebar-menu-spacer);
	padding-left: 3px;
}

.sm-sidebar-menu-stepper > div > div > button,
.sm-sidebar-menu-stepper > div > div:has(span) {
	padding-left: 0;
}

.MuiStepIcon-root.Mui-active {
	box-sizing: border-box;
	color: #602483;
	border: 2px solid #602483;
	border-radius: 50%;
	fill: #fff;
}

.sm-sidebar-logout {
	margin-top: var(--sm-sidebar-menu-spacer) !important;
}

.sm-sidebar-text {
	margin: 10px auto;
	max-width: 75%;
}

.sm-sidebar-text > p {
	text-align: left;
}
