:root {
	--sm-green: #e7f5f6;
	--sm-link-blue: #0000ff;
}

@font-face {
	font-display: swap;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/open-sans-v36-latin-regular.woff2') format('woff2');
}

* {
	font-family: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', system-ui,
		sans-serif !important;
}

.test {
	border: 1px solid red !important;
}

.max-width-1600 {
	max-width: 1600px;
}

.max-width-1500 {
	max-width: 1500px;
}

.margin-50 {
	margin: 50px;
}

.flex {
	display: flex;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-right {
	margin-right: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.flex-equal-size-children > * {
	flex: 1;
	min-width: 0;
}

.flex-small-first-child > *:first-child {
	flex: 1;
}

.flex-small-first-child > *:not(:first-child) {
	flex: 2;
}

.flex-small-last-child > *:last-child {
	flex: 1;
}

.flex-small-last-child > *:not(:last-child) {
	flex: 2;
}

.overflow-wrap-anywhere {
	overflow-wrap: anywhere;
}

.sm-filters-container {
	max-width: 1200px;
	margin: 30px 0;
}

.sm-filters-container .sm-filter-input,
.sm-filters-container .sm-label {
	margin-left: 0 !important;
}

.sm-talents-table-container > .sm-filters-container {
	max-width: 98%;
	margin-left: 15px;
}

textarea,
input {
	border-color: #bfbfbf;
	border-radius: 4px;
}

textarea:focus-visible,
input:focus-visible {
	outline: #b34090 auto 1px;
}

.letter-spacing-1 {
	letter-spacing: 1px;
}

.lowercase {
	text-transform: lowercase;
}

.font-size-12 {
	font-size: 12px;
}

.font-size-responsive-heading {
	font-size: 42px;
}

.text-align-center {
	text-align: center;
}

mark {
	background-color: #66c2c9;
	padding-left: 3px;
	padding-right: 3px;
	border-radius: 4px;
}

.border-radius-4 {
	border-radius: 4px;
}

.border-radius-6 {
	border-radius: 6px;
}

.border-light-grey {
	border: 1px solid #00000033;
}

.text-purple {
	color: #b34090;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.opacity-half {
	opacity: 0.5;
}

.font-size-small {
	font-size: small;
}

.right-top-corner-20 {
	position: absolute;
	top: 20px;
	right: 20px;
}

.position-relative {
	position: relative;
}

.flex-row-gap-10 {
	display: flex;
	gap: 10px;
}

.width-100 {
	width: 100%;
}

.max-width-fit-content {
	max-width: fit-content !important;
}

.hover-light-grey:hover {
	background-color: #f5f5f5;
}

.margin-right-10 {
	margin-right: 10px;
}

.no-webkit-tap-highlight-color {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sm-mui-input-border {
	border: 1px solid #bfbfbf;
	border-radius: 4px;
	padding: 10px;
}

.sm-mui-list-item-separator div {
	border-bottom: 1px solid #bfbfbf;

	&:last-child {
		border: 0;
	}
}

.background-pink {
	background-color: #f2edf5;
}

.background-teal {
	background-color: #e7f5f6;
}

.background-color-sm-green {
	background-color: var(--sm-green) !important;
}

.padding-horizontal-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.color-purple {
	color: #602483;
}

.padding-vertical-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.color-grey {
	color: hsl(0, 0%, 47%);
}

.gap-10 {
	gap: 10px;
}

.gap-25 {
	gap: 25px;
}

.gap-50 {
	gap: 50px;
}

.gap-desktop-20 {
	gap: 20px;
}

.max-width-300 {
	max-width: 300px;
}

.full-width {
	width: 100% !important;
}

.padding-right-50 {
	padding-right: 50px !important;
}

.border-right {
	border-right: 1px solid #797979 !important;
}

.bold-labels .sm-label-bold {
	font-weight: 600;
	color: #000 !important;
}

.line-height-2 {
	line-height: 2;
}

.margin-bottom-50 {
	margin-bottom: 50px;
}

.color-white {
	color: #fff;
}

.overflow-scroll {
	overflow: auto;
}

.not-desktop {
	display: none;
}

.align-self-flex-start {
	align-self: flex-start;
}

.height-fit-content {
	height: fit-content;
}

.align-self-center {
	align-self: center;
}

@media (max-width: 1214.98px) {
	.sm-talents-table-container > .sm-filters-container {
		margin-right: 15px;
	}

	.tablet-no-border {
		border: none !important;
	}

	.tablet-flex-column {
		flex-direction: column;
	}

	.tablet-no-gap {
		gap: 0;
	}
}

@media (max-width: 1049.98px) {
	.flex {
		flex-direction: column;
	}
	.gap-desktop-20 {
		gap: unset;
	}
}

@media (max-width: 1015px) {
	.flex-column-1009 {
		flex-direction: column;
	}
}

@media (max-width: 919.98px) {
	.not-desktop {
		display: unset;
	}

	.desktop-only {
		display: none;
	}

	.margin-50 {
		margin: 15px;
	}

	.margin-bottom-20-media-919 {
		margin-bottom: 20px;
	}
}

@media (max-width: 544.98px) {
	.mobile-flex-column {
		flex-direction: column;
	}

	.mobile-flex-left-align {
		align-items: start;
	}

	.mobile-full-width {
		max-width: 100% !important;
		width: 100% !important;
	}

	.mobile-gap-0 {
		gap: 0;
	}

	.mobile-gap-50 {
		gap: 50px;
	}

	.mobile-no-padding {
		padding: 0 !important;
	}

	.font-size-responsive-heading {
		font-size: 36px;
	}
}
