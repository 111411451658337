.sm-profile-textarea-heading {
	width: 100%;
}

.sm-profile-textarea-character-counter {
	position: relative;
	font-size: 12px;
	margin: 5px 0 0px 0;
}

.sm-profile-textarea-character-counter > span {
	position: absolute;
	bottom: 0;
	right: 0;
}

.sm-profile-hidden-textarea-checkbox {
	position: relative;
	height: 5px;
}

.sm-profile-hidden-textarea-checkbox input {
	pointer-events: none;
}
