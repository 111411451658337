.sm-work-with-me-box-chip-container {
	display: flex;
	flex-wrap: wrap;
}

.sm-multiline-chip {
	height: auto !important;
}

.sm-multiline-chip .MuiChip-label {
	padding-top: 6px;
	padding-bottom: 6px;
	display: block;
	white-space: normal;
	overflow-wrap: anywhere;
}
