.sm-navbar-image {
	max-height: 40px;
	height: 40px;
	width: auto;
	cursor: pointer;
}

.sm-language-toggle > a,
.sm-language-toggle > a,
.sm-language-toggle > a:focus,
.sm-language-toggle > a:active {
	text-decoration: none;
}

.sm-active-language {
	--sm-text-button-label-text-color: #602483;
	--sm-text-button-label-font-weight: 400;
	font-size: 20px;
}

#sm-navbar {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #000;
	min-height: 40px;
}

@media (max-width: 919.98px) {
	.sm-navbar-menu {
		display: initial;
	}
}
