.sm-step-icon {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid #fff;
	box-sizing: border-box;
}

.sm-step-icon-disc {
	background-color: #fff;
	height: 100%;
	width: 0%;
}

.sm-step-status-in-progress .sm-step-icon-disc {
	width: 50%;
}

.sm-step-status-done .sm-step-icon-disc {
	width: 100%;
}

.sm-step-status-done {
	color: #000;
	content: "✓";
}

.sm-step-status-done img {
	padding-bottom: 1px;
}

.MuiStepConnector-line {
	border-left: 2px solid #fff !important;
}
