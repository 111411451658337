.sm-reviewer-filters-container {
	max-width: 1550px;
	margin: 30px 0;
}

.sm-drop-filter-container {
	margin-top: 15px;
}

.sm-reviewer-filters-result {
	margin-left: 15px;
	margin-top: 25px;
}

/* fix for weird tablet size */
@media (max-width: 1214.98px) {
	.sm-reviewer-text-filter-container {
		width: 98%;
	}
	.sm-drop-filter-container {
		flex-direction: column;
	}
}

@media (max-width: 1049.98px) {
	.sm-reviewer-filters-container {
		flex-direction: column;
		width: 96%;
		justify-items: center;
		margin: 30px auto;
	}

	/* fix for text field on mobile  */
	.sm-reviewer-text-filter-container {
		width: 97.5%;
	}
}
