.sm-footer {
	border-top: 10px solid #797979;
	border-bottom: 1px solid #000;
}

.sm-footer-content {
	--padding-x: 20%;
	display: flex;
	justify-content: space-between;
}

.sm-footer-content-section {
	border-bottom: 1px solid #000;
	min-width: 25%;
	box-sizing: border-box;
	overflow: hidden;
}

.sm-footer-content-title-box {
	border-bottom: 1px solid #000;
	min-width: 190px;
}

.sm-footer-content-title,
.sm-footer-content-list {
	padding: 15px 0 15px var(--padding-x);
}

.sm-footer-content-title {
	padding-bottom: 5px;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1px;
}

.sm-footer-content-list {
	padding-left: var(--padding-x);
	display: flex;
	flex-direction: column;
	min-width: 200px;
}

.sm-footer-bottom {
	display: flex;
	justify-content: space-between;
	max-width: 95%;
	margin: auto;
}

.sm-footer-content-list a > button {
	color: #000;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: -0.5px;
	position: relative;
	left: -15px;
	min-width: max-content;
}

.sm-footer-content-list a > button:hover {
	background-color: transparent;
	color: #000;
}

#sm-social-media-list {
	flex-direction: row;
	justify-content: flex-start;
}

button > img {
	height: 25px;
}

/* Smallish devices (tablets, less than 1273px) */
@media (max-width: 1272.98px) {
	.sm-footer-content {
		flex-wrap: wrap;
		gap: 0;
	}

	.sm-footer-content-section {
		min-width: 50%;
		border-top: 10px solid #797979;
	}

	.sm-footer {
		border-top: none;
	}
}
/* Small devices (less than 545px) */
@media (max-width: 544.98px) {
	.sm-footer-content-section {
		min-width: 100%;
	}
}
/* Super-small devices (less than 350px) */
@media (max-width: 349.98px) {
	.sm-footer-bottom {
		flex-direction: column;
	}

	.sm-footer-bottom > p {
		text-align: center;
	}
}
