.sm-sidebar-gradient {
	background-image: url('../../assets/sidebar-gradient.png');
}

.sm-sidebar-title > h1 {
	font-size: 20px;
	font-weight: 600;
}

.sm-sidebar-title {
	margin: 28px auto;
	max-width: 85%;
}

.sm-sidebar-menu > button {
	text-align: left;
}

@media (max-width: 1499.98px) {
	.sm-sidebar-title {
		margin: 20px;
	}
}
