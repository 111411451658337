.sm-scrummatch-intro {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	padding-bottom: 100px;
}

.sm-scrummatch-intro-action-container {
	margin-top: 50px;
}
